export const projects = [
  {
    img: "https://github.com/Surjesh-Yadav/Projects-Photos/blob/main/Screenshot%20(554).png?raw=true",
    title: "AppleTv+ clone",
    des: "The Apple Tv is an entertainment platform that allows you to stream award-winning series, dramas, documentaries, kids’ entertainment, comedies and more.",
    demo: "",
    live: "https://lucid-ritchie-187c42.netlify.app/",
    gitHub: "https://github.com/Surjesh-Yadav/AppleTv",
    technologies: ["JavaScript", "HTML", "CSS", "Node.js", "MongoDB"],
  },
  {
    img: "/indiarealty.png",
    title: "India Realty",
    des: "Real estate website with full functionality",
    demo: "",
    live: "https://india-realty.mywwwserver.in/",
    gitHub: "#",
    technologies: ["ReactJs", "Nextjs", "Tailwind CSS"],
  },
  {
    img: "/ttm.png",
    title: "The Tech Makers",
    des: "Web Development Company",
    demo: "",
    live: "https://www.thetechmakers.com/",
    gitHub: "#",
    technologies: ["JavaScript", "ReactJs", "NextJs", "Tailwind CSS"],
  },
  {
    img: "/kyber.png",
    title: "Kyber Swap",
    des: "A blockchain project landing page using Next.js and Tailwind CSS, translating a Figma design into a seamless user experience.",
    demo: "",
    live: "https://kyberswap.netlify.app/",
    gitHub: "https://github.com/surjesh-yadav/landingpage",
    technologies: [
      "Nextjs",
      "Tailwind CSS",
      "JavaScript",
    ],
  },

  {
    img: "/keprl.png",
    title: "Keprl",
    des: "A blockchain project landing page using Next.js and Tailwind CSS, translating a Figma design into a seamless user experience.",
    demo: "",
    live: "https://keplr-seven.vercel.app/",
    gitHub: "#",
    technologies: [
      "Reactjs",
      "Tailwind Css",
      "Next Js",
    ],
  },

  {
    img: "/women.png",
    title: "Women Choice",
    des: "This is a e-commerce website for Womens, with add to cart and login sign-up functionality",
    demo: "",
    live: "https://women-choice.netlify.app/",
    gitHub: "https://github.com/surjesh-yadav/WomenChoice-Backend",
    technologies: [
      "HTML",
      "CSS",
      "JavaScript",
      "Bootstrap",
    ],
  },
  
];
