import React from 'react';
import { ThemeContext } from '../../ContextProvider/ThemeContext';
import WindowSize from '../../Utils/WindowSize';
import styles from './About.module.css';

const About = () => {
	const { newTheme } = React.useContext(ThemeContext);
	const [width] = WindowSize();
	const img =
		width < 650
			? 'https://media-exp1.licdn.com/dms/image/C4D03AQES6GZBA3QW8A/profile-displayphoto-shrink_200_200/0/1648457713908?e=1656547200&v=beta&t=3BZ2-h2KqKZYUGynrj2kDJTKeVg-kPPfX-Xqg2KlCuU' // my Picture
			: 'https://www.damiestechnologies.com/img/programmer.gif';
	return (
		<div
			className={styles.container}
			style={{ boxShadow: `3px 3px 5px ${newTheme.line}` }}
		>
			<div className={styles.first}>
				<img src={"/developer.gif"} alt='' />
			</div>
			<div className={styles.second}>
				<h1
					style={{ color: `${newTheme.title}` }}
					className={styles.heading}
				>
					About Me
				</h1>
				<div className={styles.borderBottom} />
				<p
					style={{ color: `${newTheme.para}` }}
					className={styles.aboutMe}
				>
					Experienced Frontend Developer with a strong command of React, Next.js, Tailwind CSS, and expertise in Web3 blockchain technologies. With a solid foundation built over 2 years of professional experience, I specialize in crafting dynamic and responsive user interfaces that seamlessly integrate with blockchain ecosystems.


					<span style={{ color: `#00a0a0` }}>
						{' '}
						Looking for a challenging role in a growth
oriented company.
					</span>
					.
				</p>
			</div>
		</div>
	);
};

export default About;
