import React, { useContext } from 'react';
import { ThemeContext } from '../../ContextProvider/ThemeContext';
import styles from './Experiences.module.css';

const Experiences = () => {
	const { newTheme } = useContext(ThemeContext);
	return (
		<div className={styles.experiences}>
			<h1
				style={{ color: `${newTheme.title}` }}
				className={styles.heading}
			>
				Experience
			</h1>
			<div className={styles.borderBottom} />

			<div className={styles.container}>
				<div className={styles.position}>
				Frontend Developer | Wordpress Developer{' '}
					<a href=''>  The Tech Makers, Noida</a>
				</div>
				<div className={styles.date}>January 2023 - January 2024</div>
				<fieldset className={styles.responsibilites}>
					<legend>
						<h3> Roles & responsibilites </h3>
					</legend>
					<ul>
						<li>
						I specialized in frontend development, seamlessly connecting with APIs and utilizing fetch for data integration.
                           </li>
						<li>
                        Transforming designs from Figma into interactive experiences was my forte
						</li>
						<li>
                        I excelled in implementing them using Next.js. 
							</li>
							<li>
                            My focus on bridging design and development ensured a cohesive and visually appealing user interface.

							</li>
					
					</ul>
				</fieldset>
			</div>

			<br/>

			<div className={styles.container}>
				<div className={styles.position}>
				MERN Stack Developer {' '}
					<a href='/'>Masai School </a>
				</div>
				<div className={styles.date}>October 2021 - July 2022</div>
				<fieldset className={styles.responsibilites}>
					<legend>
						<h3> Roles & responsibilites </h3>
					</legend>
					<ul>
						<li>
						During Masai's Full Stack Web Development program, I demonstrated exceptional commitment and achieved outstanding results. 

							
						</li>
						<li>Completing 2160 hours of practical learning reflects my dedication to mastering the curriculum and applying industry standards and methods with excellence. 
							
						</li>
						<li>
                        My performance stands as a testament to my competence and proficiency in full-stack web development.

						</li>
					
					</ul>
				</fieldset>
			</div>

		</div>
	);
};

export default Experiences;
